import * as React from "react";
import { cn } from "../../lib/utils";

import { Check, ChevronsUpDown } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export type OptionType = {
  label: string;
  value: string;
  image?: string;
};

interface MultiSelectProps {
  options: OptionType[];
  placeholder: string;
  selected: string[];
  onChange?: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeValue?: (values: string[]) => void;
  className?: string;
  buttonStyles?: string;
  spanStyles?: string;
  onFilterChange?: (value: string) => void;
  idRender?: (id: string) => string;
  showId?: boolean;
  singleSelect?: boolean;
}

function MultiSelect({
  options,
  selected,
  onChange,
  className,
  buttonStyles,
  placeholder,
  onChangeValue,
  onFilterChange,
  showId = false,
  idRender,
  spanStyles,
  singleSelect = false,
  ...props
}: MultiSelectProps) {
  const [open, setOpen] = React.useState(false);

  const handleUnselect = (item: string) => {
    onChange?.(selected.filter((i) => i !== item));
  };

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full 1w-48 justify-between 1bg-accent/20 ${
            selected.length > 1 ? "h-full" : "h-10"
          } ${buttonStyles}`}
          onClick={() => setOpen(!open)}
        >
          <div className="flex">
            {!selected.length && (
              <span className="text-muted-foreground whitespace-nowrap mr-6">
                {placeholder}
              </span>
            )}
            {selected[0] && (
              <div className="flex items-center gap-x-2">
                {options.find((option) => option.value === selected[0])
                  ?.image && (
                  <Avatar className="w-5 h-5">
                    <AvatarImage
                      src={
                        options.find((option) => option.value === selected[0])
                          ?.image
                      }
                    />
                    <AvatarFallback>?</AvatarFallback>
                  </Avatar>
                )}

                <span
                  className={`truncate text-left ${
                    spanStyles ? spanStyles : "w-20"
                  }`}
                >
                  {
                    options.find((option) => option.value === selected[0])
                      ?.label
                  }
                </span>
                {selected.length > 1 && <span>+ {selected.length - 1}</span>}
              </div>
            )}
            {/* <span className="w-24 text-left relative">
               <span className="w-4 truncate">
                 {options.find((option) => option.value === selected[0])?.label}
               </span>
               <span className="absolute right-0 z-10 bg-background">
                 +{selected.length}
               </span>
             </span> */}
            {/* {selected.map((item) => (
              <Badge
                variant="secondary"
                key={item}
                className="mr-1 mb-1"
                onClick={() => handleUnselect(item)}
              >
                {item}
                <button
                  className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUnselect(item);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => handleUnselect(item)}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            ))} */}
          </div>
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="1w-full p-0 w-56">
        <Command
          filter={(value, search) => {
            console.log({ value, search });

            if (onFilterChange) {
              return 1;
            }

            if (value.includes(search)) {
              return 1;
            }

            return 0;
          }}
          className={className}
        >
          <CommandInput
            onValueChange={(value) => {
              onFilterChange?.(value);
            }}
            placeholder="Search ..."
          />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-auto">
            {options.map((option) => (
              <CommandItem
                key={option.value}
                onSelect={() => {
                  if (singleSelect) {
                    onChange?.([option.value]);
                    onChangeValue?.([option.value]);
                  } else {
                    onChange?.(
                      selected.includes(option.value)
                        ? selected.filter((item) => item !== option.value)
                        : [...selected, option.value],
                    );
                    onChangeValue?.(
                      selected.includes(option.value)
                        ? selected.filter((item) => item !== option.value)
                        : [...selected, option.value],
                    );
                  }
                  setOpen(!singleSelect);
                }}
                value={option.label}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    selected.includes(option.value)
                      ? "opacity-100"
                      : "opacity-0",
                  )}
                />
                {option.image && (
                  <Avatar className="w-5 h-5 mr-2">
                    <AvatarImage src={option.image} />
                    <AvatarFallback>?</AvatarFallback>
                  </Avatar>
                )}
                <span className="truncate w-32">
                  {showId
                    ? idRender
                      ? idRender(option.value)
                      : `${option.value}: `
                    : ""}
                  {option.label}
                </span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export { MultiSelect };
