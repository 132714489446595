"use client";

import { checklists, inpectionsHooks } from "../../../../api";
import { schemas } from "../../../../api/schema";
import { queryClient } from "../../../context/query-client";
import { Button } from "../../../ui/button";
import { SheetContent, SheetHeader, SheetTitle } from "../../../ui/sheet";
// import UserAvatar from "@/components/user-avatar";
import TimeAgo from "javascript-time-ago";
import {
  BadgeCheck,
  Camera,
  CheckCircle2,
  CheckSquare,
  CircleDashed,
  CircleDotDashed,
  ClipboardCheck,
  ClipboardSignature,
  Hash,
  List,
  Loader2,
  PauseCircle,
  QrCode,
  ScanLine,
  TextCursorInput,
  ThumbsUp,
  XCircle,
} from "lucide-react";
import { arrayHasValue } from "../../../../lib/utils";
// import Link from "next/link";
import nl from "relative-time-format/locale/nl";
import { z } from "zod";

TimeAgo.addLocale(nl);
const timeAgo = new TimeAgo("nl-NL");

enum CheckTypes {
  okNotOk = 1,
  inputNumber,
  yesNo,
  inputSingleLine,
  scan,
  checklist,
  singleOption,
  signature = 8,
  photo = 9,
}

enum CheckState {
  Empty = 1,
  Good = 2,
  Neutral = 3,
  Tolerated = 4,
  Bad = 5,
  Recovered = 6,
}

export const checkTypeIconsMap2 = {
  [CheckTypes.okNotOk]: <CheckSquare className="w-4 h-4" />,
  [CheckTypes.yesNo]: <ThumbsUp className="w-4 h-4" />,
  [CheckTypes.scan]: <QrCode className="w-4 h-4" />,
  [CheckTypes.checklist]: <ScanLine className="w-4 h-4" />,
  [CheckTypes.inputSingleLine]: <TextCursorInput className="w-4 h-4" />,
  [CheckTypes.inputNumber]: <Hash className="w-4 h-4" />,
  [CheckTypes.singleOption]: <List className="w-4 h-4" />,
  [CheckTypes.signature]: <ClipboardSignature className="w-4 h-4" />,
  [CheckTypes.photo]: <Camera className="w-4 h-4" />,
};

type Check = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_Checks_InspectionCheckDto
>;

type Group = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_InspectionGroupDto
>;

export default function InspectionPopup({
  id,
  percentage,
}: {
  id: string;
  percentage: number;
}) {
  const { data, isLoading } = checklists.useGet("/checklists/:checklistUid", {
    params: { checklistUid: id },
  });

  const { mutate: mutateAccept, isLoading: isLoadingMutateAccept } =
    checklists.usePut("/checklists/accept");

  const { mutate: mutateUndoAccept, isLoading: isLoadingMutateUndoAccept } =
    checklists.usePut("/checklists/accept-undo");

  function checkAccepted(check: Check) {
    return (
      check.checkState === CheckState.Good ||
      check.checkState === CheckState.Tolerated ||
      check.checkState === CheckState.Neutral ||
      check.checkState === CheckState.Recovered
    );
  }

  function unfinishedChecksForGroup(group: Group) {
    return group.checks?.filter((c) => !checkAccepted(c));
  }

  const disruptions = data?.groups?.reduce((a, b) => {
    return [
      ...a,
      ...(b.checks?.filter(
        (c) =>
          c.checkState === CheckState.Bad ||
          c.checkState === CheckState.Recovered,
      ) ?? []),
    ];
  }, [] as Check[]);

  async function handleAcceptInspection(uid: string) {
    console.log({ uid });

    await mutateAccept(
      { checklistUid: uid },
      {
        onSuccess(data, variables, context) {
          if (isLoadingMutateUndoAccept) {
            return;
          }

          const key = inpectionsHooks.getKeyByPath(
            "get",
            "/inspections/search",
          );
          queryClient.invalidateQueries(key);

          const key2 = checklists.getKeyByPath(
            "get",
            "/checklists/:checklistUid",
          );
          queryClient.invalidateQueries(key2);
        },
      },
    );
  }

  async function handleUndoAcceptInspection(uid: string) {
    if (
      confirm(
        "Weet je zeker dat je de acceptatie van deze inspectie ongedaan wilt maken?",
      )
    ) {
      await mutateUndoAccept(
        { checklistUid: uid },
        {
          onSuccess(data, variables, context) {
            const key = inpectionsHooks.getKeyByPath(
              "get",
              "/inspections/search",
            );
            queryClient.invalidateQueries(key);

            const key2 = checklists.getKeyByPath(
              "get",
              "/checklists/:checklistUid",
            );
            queryClient.invalidateQueries(key2);
          },
        },
      );
    }
  }

  return (
    <SheetContent className="overflow-y-auto pt-0 w-full max-w-4xl 1dark:bg-body-background dark:bg-background bg-white px-0">
      <SheetHeader className="border-b pt-6 1bg-body-background">
        <SheetTitle className="text-3xl font-semibold flex items-center px-6">
          <ClipboardCheck className="w-6 h-6 mr-2" />
          {data?.title}
        </SheetTitle>
        <div className="grid pb-2 px-6 divide-y w1-56 1grid-cols-[120px_120px_120px_120px_120px_120px] pt-2">
          <div className="flex border-t items-center justify-between h-11">
            <span className="block text-muted-foreground text-sm">
              Inspectie
            </span>
            <a
              href={`/inspections/${data?.uid}/checklist`}
              className="block text-sm text-blue-500 hover:text-blue-400 cursor-pointer truncate overflow-hidden 1pr-8 font-mono"
            >
              Bekijken
            </a>
          </div>
          <div className="flex items-center justify-between h-11">
            <span className="block text-muted-foreground text-sm">Nummer</span>
            <span className="block text-primary text-sm truncate overflow-hidden 1pr-8 font-mono">
              {data?.serialNumber}
            </span>
          </div>
          <div className="flex items-center justify-between h-11">
            <span className="block text-muted-foreground text-sm whitespace-nowrap">
              Inspectie-ID
            </span>
            <span className="block text-primary pl-8 text-sm truncate overflow-hidden 1pr-8 font-mono">
              {data?.uid}
            </span>
          </div>
          <div className="flex items-center justify-between h-11">
            <span className="block text-muted-foreground text-sm">Status</span>
            <span className="text-sm text-primary flex items-center font-mono">
              {data?.accepted && (
                <span
                  className={`bg-emerald-500 font-sans font-semibold whitespace-nowrap flex items-center text-sm pr-2 pl-1.5 py-0.5 rounded-md text-black w-fit`}
                >
                  <BadgeCheck className="text-black mr-1 w-4 h-4" />
                  Afgetekend
                </span>
              )}
              {!data?.accepted &&
                percentage < 100 &&
                !arrayHasValue(
                  disruptions?.filter((d) => d.checkState === CheckState.Bad),
                ) && (
                  <span
                    className={`dark:bg-neutral-400 font-sans font-semibold bg-neutral-300 whitespace-nowrap flex items-center text-sm pr-2 pl-1.5 py-0.5 rounded-md text-black w-fit`}
                  >
                    <div className="flex items-center justify-center gap-x-1.5 relative mr-1">
                      <div className="w-1 h-1 rounded-full bg-black absolute"></div>
                      <Loader2 className="animate-[spin_2s_linear_infinite] w-4 h-4 text-black" />
                    </div>
                    Actief
                  </span>
                )}
              {!data?.accepted &&
                arrayHasValue(
                  disruptions?.filter((d) => d.checkState === CheckState.Bad),
                ) && (
                  <span
                    className={`bg-orange-400 font-sans font-semibold whitespace-nowrap flex items-center text-sm pr-2 pl-1.5 py-0.5 rounded-md text-black w-fit`}
                  >
                    <XCircle className="text-black mr-1 w-4 h-4" />
                    Verstoring
                  </span>
                )}

              {!data?.accepted && percentage >= 100 && (
                <span
                  className={`bg-indigo-400 font-sans font-semibold whitespace-nowrap flex items-center text-sm pr-2 pl-1.5 py-0.5 rounded-md text-black w-fit`}
                >
                  <PauseCircle className="text-black mr-1 w-4 h-4" />
                  Afgerond
                </span>
              )}
            </span>
          </div>
          <div className="flex items-center justify-between h-11">
            <span className="block text-muted-foreground text-sm">Gestart</span>
            <div className="font-mono flex items-center gap-x-2">
              <span className="block text-primary text-sm font-mono">
                {data?.createdAt &&
                  timeAgo.format(new Date(data?.createdAt ?? 0))}
              </span>
              {/* <UserAvatar narrow small id={data?.createdBy?.value} /> */}
            </div>
          </div>
          <div className="flex items-center justify-between h-11">
            <span className="block text-muted-foreground text-sm">
              Geaccepteerd
            </span>

            {!data?.accepted && percentage >= 100 ? (
              <Button
                onClick={() => handleAcceptInspection(data?.uid ?? "")}
                size={"sm"}
                variant={"outline"}
                className="h-6 bg-emerald-600/20 font-semibold border-emerald-600 text-emerald-600"
              >
                Inspectie accepteren
              </Button>
            ) : (
              <div className="font-mono flex items-center gap-x-2">
                {!data?.acceptedAt ? (
                  "-"
                ) : (
                  <>
                    <Button
                      onClick={() =>
                        handleUndoAcceptInspection(data?.uid ?? "")
                      }
                      size={"sm"}
                      variant={"outline"}
                      className="h-6 mr-2 bg-red-600/20 font-semibold border-red-600 text-red-600"
                    >
                      Ongedaan maken
                    </Button>
                    <span className="block text-primary text-sm font-mono">
                      {data?.acceptedAt &&
                        timeAgo.format(new Date(data?.acceptedAt ?? 0))}
                    </span>
                    {/* <UserAvatar narrow small id={data?.acceptedBy?.value} /> */}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </SheetHeader>

      <div className="px-6 pt-6 grid gap-y-2">
        <span className="block text-xl font-semibold">Checklist</span>
        <div className="">
          <div className="flex justify-between w-full">
            <span className="block text-sm text-muted-foreground">
              Voortgang
            </span>
            <span className="block text-sm text-muted-foreground">
              {percentage.toFixed()}%
            </span>
          </div>
          <div className="text-sm relative block text-muted-foreground/60 pt-2 pb-1">
            <div className="h-2 rounded-full bg-accent/80 dark:bg-accent/60 relative">
              <div
                className={`absolute h-full bg-emerald-500 rounded-full`}
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          {data?.groups?.map((group) => (
            <div key={group.uid}>
              <div className="h-10 rounded-md bg-accent dark:bg-accent/60 flex items-center justify-between px-4">
                <span className="block text-muted-foreground font-semibold text-sm">
                  {group.title}
                </span>

                <div className="ml-2">
                  {arrayHasValue(unfinishedChecksForGroup(group)) ? (
                    <CircleDashed className="text-muted-foreground/60 w-5 h-5 animate-[spin_4s_linear_infinite]" />
                  ) : (
                    <CheckCircle2 className="text-emerald-500 w-5 h-5" />
                  )}
                </div>
              </div>

              <div className="1divide-y divide-dashed dark:divide-border/60 divide-border space-y-2 py-2">
                {group.checks?.map((check) => (
                  <div
                    key={check.uid}
                    className={`h-8 rounded-md px-4 grid grid-cols-[auto_1fr_auto_auto] items-center ${
                      check.checkState === CheckState.Bad
                        ? "dark:bg-red-800/20 dark:text-red-500 dark:hover:bg-red-800/30 bg-red-900/10 text-red-700 hover:bg-red-900/20"
                        : "text-muted-foreground"
                    }
                    ${
                      check.checkState === CheckState.Recovered
                        ? "dark:bg-yellow-800/20 dark:text-yellow-500 dark:hover:bg-yellow-800/30 bg-yellow-600/10 text-yellow-700 hover:bg-yellow-600/20"
                        : "text-muted-foreground"
                    }

                    ${
                      check.checkState !== CheckState.Bad &&
                      check.checkState !== CheckState.Recovered
                        ? "dark:hover:bg-accent/50 hover:bg-accent/80"
                        : ""
                    }
                    `}
                  >
                    <div
                      className={`${
                        check.checkState === CheckState.Bad
                          ? "dark:text-red-500 text-red-700"
                          : "text-muted-foreground"
                      } 
                      ${
                        check.checkState === CheckState.Recovered
                          ? "dark:text-yellow-500 text-yellow-700"
                          : "text-muted-foreground"
                      } 
                      mr-3`}
                    >
                      {checkTypeIconsMap2[check.type ?? 1]}
                    </div>

                    <span className="block font-semibold truncate overflow-hidden text-inherit text-sm pr-3">
                      {check.description}
                    </span>

                    <div className="mr-3 border bg-secondary w-fit h-fit rounded-md px-2 overflow-hidden truncate max-w-[200px]">
                      {check.type === CheckTypes.okNotOk &&
                        check.checkState !== 1 && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium text-xs text-muted-foreground font-mono leading-none ">
                              {(check as any).checkState === 2 && "Goedgekeurd"}
                              {(check as any).checkState === 5 && "Afgekeurd"}
                              {(check as any).checkState === 6 && "Hersteld"}
                            </span>
                          </div>
                        )}

                      {check.type === CheckTypes.inputNumber &&
                        (check as any).number !== null && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium text-xs text-muted-foreground font-mono leading-none">
                              {(check as any).number}
                            </span>
                          </div>
                        )}

                      {check.type === CheckTypes.yesNo &&
                        (check as any).answer !== null && (
                          <div className="flex gap-x-1.5">
                            <div className="h-fit 1min-h-[32px] items-start py-1 rounded-md px-1 w-fit 1mt-2.5 flex gap-x-2">
                              <span className="font-medium w-fit text-xs text-muted-foreground font-mono leading-none block">
                                {`${(check as any).answer ? "Ja" : "Nee"}`}
                              </span>
                            </div>
                          </div>
                        )}

                      {check.type === CheckTypes.inputSingleLine &&
                        (check as any).text !== null && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium max-w-[164px] text-xs text-muted-foreground font-mono leading-none ">
                              {(check as any).text}
                            </span>
                          </div>
                        )}

                      {check.type === CheckTypes.scan &&
                        (check as any).barcode !== null && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium max-w-[164px] text-xs text-muted-foreground font-mono leading-none ">
                              {(check as any).barcode}
                            </span>
                          </div>
                        )}

                      {check.type === CheckTypes.checklist &&
                        (check as any).serialNumber !== null && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium max-w-[164px] text-xs text-muted-foreground font-mono leading-none ">
                              {(check as any).serialNumber}
                            </span>
                          </div>
                        )}
                      {check.type === CheckTypes.signature &&
                        (check as any).signed && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium max-w-[164px] text-xs text-muted-foreground font-mono leading-none ">
                              Gesigneerd
                            </span>
                          </div>
                        )}
                      {check.type === CheckTypes.singleOption &&
                        (check as any).selectedChoice !== null && (
                          <div className="h-fit flex items-center py-1 rounded-md px-1 w-fit 1mt-2.5">
                            <span className="font-medium max-w-[164px] text-xs text-muted-foreground font-mono leading-none ">
                              {
                                (check as any).choices[
                                  (check as any).selectedChoice
                                ]
                              }
                            </span>
                          </div>
                        )}
                    </div>

                    <div
                      className={`ml-auto flex items-center gap-x-3 ${
                        check.checkState === CheckState.Bad
                          ? "dark:text-red-500 text-red-700"
                          : "text-muted-foreground"
                      }
                      ${
                        check.checkState === CheckState.Recovered
                          ? "dark:text-yellow-500 text-yellow-700"
                          : "text-muted-foreground"
                      }
                      `}
                    >
                      {check.lastTouchedBy && (
                        <>
                          <span className="text-sm whitespace-nowrap">
                            {timeAgo.format(new Date(check.lastTouchedAt ?? 0))}
                          </span>
                          {/* <UserAvatar id={check.lastTouchedBy?.value} /> */}
                        </>
                      )}

                      {checkAccepted(check) &&
                        check.checkState !== CheckState.Recovered && (
                          <CheckCircle2 className="text-emerald-500 w-5 h-5" />
                        )}

                      {check.checkState === CheckState.Recovered && (
                        <CheckCircle2 className="dark:text-yellow-500 text-yellow-600 w-5 h-5" />
                      )}

                      {!checkAccepted(check) && (
                        <>
                          {check.isRequired ? (
                            <CircleDotDashed
                              className={`w-5 h-5 ${
                                check.checkState === CheckState.Bad
                                  ? "text-red-700"
                                  : "text-muted-foreground/60"
                              }`}
                            />
                          ) : (
                            <CircleDashed className="w-5 h-5 text-muted-foreground/60" />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </SheetContent>
  );
}
