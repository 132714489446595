import TimeAgo from "javascript-time-ago";
import {
  ArrowRight,
  CheckCircle2,
  Circle,
  CircleDot,
  CircleDotDashed,
  Loader2,
  PackagePlus,
  ScanLine,
  Search,
  User,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import nl from "relative-time-format/locale/nl";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import {
  checklistsHooks,
  inpectionsHooks,
  projectsHooks,
  templateChecklistsHooks,
} from "../../../api";
import { schemas } from "../../../api/schema";
import { useUserStore } from "../../../stores/project-store";
import { useScanner } from "../../hooks/useMemor10Scanner";
import ScannerPopup from "../../molecules/ScannerPopup";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import UserAvatar from "../../molecules/user-avatar";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion";
import { Input } from "../../ui/input";
import { Toggle } from "../../ui/toggle";
import { useToast } from "../../ui/use-toast";

export type TemplateChecklist = z.infer<
  typeof schemas.Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto
>;

export enum ChecklistState {
  Accepted = 1,
  ApprovalRequired = 2,
  InComplete = 3,
  //TODO: add enum value for checklist that have a rejected check (also for back-end).
  // Rejected = 4
}

const ChecklistStateLabelMap = {
  [ChecklistState.Accepted]: "Geaccepteerd",
  [ChecklistState.ApprovalRequired]: "Aftekenen",
  [ChecklistState.InComplete]: "Actief",
};

enum InspectionsQueryOrdeBy {
  DefaultOrder = 0,
  CreatedAtAscending,
  CreatedAtDescending,
  UpdatedAtAscending,
  UpdatedAtDescending,
  AcceptedAtAscending,
  AcceptedAtDescending,
  TitleAscending,
  TitleDescending,
  SerialNumberAscending,
  SerialNumberDescending,
}

TimeAgo.addLocale(nl);

export const ProductChecklistSelectPage: React.FC = () => {
  const timeAgo = new TimeAgo("nl-NL");
  const { loggedInUser } = useUserStore();
  const navigate = useNavigate();
  const { projectId, productLabel } = useParams<{
    projectId: string | undefined;
    productLabel: string | undefined;
  }>();
  // const [searchValue, setSearchValue] = useState("");
  const [productLabelSearchValue, setProductLabelSearchValue] = useState(
    productLabel || "",
  );
  const [debouncedProductLabelSearchValue] = useDebounce(
    productLabelSearchValue,
    300,
  );
  const [serialNumberSearchValue, setSerialNumberSearchValue] = useState("");
  const [debouncedSerialNumberSearchValue] = useDebounce(
    serialNumberSearchValue,
    300,
  );

  const templateChecklistsSearchEnabled =
    debouncedProductLabelSearchValue !== "";
  const checklistsSearchEnabled = debouncedSerialNumberSearchValue !== "";

  const { data: templateChecklists, isLoading: isLoadingTemplateChecklists } =
    templateChecklistsHooks.useGet(
      "/templates/checklists/by-product-label/:productLabel",
      { params: { productLabel: debouncedProductLabelSearchValue } },
      { enabled: templateChecklistsSearchEnabled },
    );

  const { data: templateChecklistsTemplates } = templateChecklistsHooks.useGet(
    "/templates/checklists",
    { queries: { page: 1, size: 100 } },
  );

  const [userChecklistsToggle, setUserChecklistToggle] = useState(false);
  const [newChecklistsToggle, setNewChecklistToggle] = useState(false);
  const [activeChecklistsToggle, setActiveChecklistToggle] = useState(false);
  const [acceptedChecklistsToggle, setAcceptedChecklistToggle] =
    useState(false);
  const [completedChecklistsToggle, setCompletedChecklistToggle] =
    useState(false);

  enum Filter {
    active = 1,
    completed,
    rejected,
    accepted,
    generated,
  }

  const { data: checklists, isLoading: isLoadingChecklists } =
    inpectionsHooks.useGet(
      "/inspections/search",
      {
        queries: {
          serialNumber: debouncedSerialNumberSearchValue,
          orderBy: InspectionsQueryOrdeBy.UpdatedAtDescending,
          page: 1,
          size: 25,
          createdBy: userChecklistsToggle
            ? [loggedInUser?.userId ?? ""]
            : undefined,
          state: newChecklistsToggle
            ? Filter.generated
            : activeChecklistsToggle
            ? Filter.active
            : acceptedChecklistsToggle
            ? Filter.accepted
            : completedChecklistsToggle
            ? Filter.completed
            : undefined,
        },
      },
      // { enabled: userChecklistsToggle },
      // { enabled: true },
    );

  const { data: recentChecklists } = inpectionsHooks.useQuery(
    "/inspections/recent",
    {
      queries: {
        page: 1,
        size: 25,
      },
    },
    { enabled: false },
  );

  const { mutate: postChecklist } = checklistsHooks.usePost("/checklists");

  const { mutate: putChecklistToProject } = projectsHooks.usePut(
    "/projects/checklists",
  );

  function handleScanner() {
    // // Check if product label and serialnumber is entered.
    // const [serialNumber, productLabel] = searchValue.split("|");
    // if (!serialNumber || !productLabel) {
    //   return;
    // }
    // setSerialNumberSearchValue(serialNumber);
    // setProductLabelSearchValue(productLabel);
  }

  function handleTemplateChecklistClick(templateChecklist: TemplateChecklist) {
    if (serialNumberSearchValue === "") {
      return;
    }

    postChecklist(
      {
        serialNumber: serialNumberSearchValue,
        productLabel: templateChecklist.title ?? "undefined",
        templateChecklistId: templateChecklist.id,
        projectId: projectId === undefined ? undefined : Number(projectId),
      },
      {
        onSuccess: (data) => {
          navigate(`/checklist/${data.checklistUid}`);
        },
      },
    );
  }

  useEffect(() => {
    document.getElementById("123")?.addEventListener("scroll", function () {
      if (
        document.activeElement &&
        document.activeElement.tagName === "INPUT"
      ) {
        (document.activeElement as HTMLInputElement).blur();
      }
    });
  }, []);

  const [scanActive, setScanActive] = useState(false);
  const {
    scan,
    scanResult: scanResultUseScanner,
    scanMethod,
    reset,
    overrideScanMethod,
    setDefaultScanMethod,
    resetCamera,
    scanning,
  } = useScanner("checklistSearchScanReader");

  const handleScannerScan = async () => {
    if (scanMethod === "text") {
      setScanActive(true);
      return;
    }

    if (scanMethod === "camera") {
      setScanActive(true);
    }

    try {
      await scan();
    } catch (e: unknown) {
      const { message } = e as Error;

      alert(`${message}`);
    }
  };

  const { toast } = useToast();

  const navigateToChecklist = async (result: string) => {
    const code = result;

    if (code.length < 1) {
      setScanActive(false);
      resetCamera();

      toast({
        title: "Invalid scan result",
        variant: "destructive",
      });

      return;
    }

    resetCamera();
    setScanActive(false);

    setSerialNumberSearchValue(code);
  };

  return (
    <LayoutV2>
      {scanMethod === "camera" && (
        <ScannerPopup
          confirmLabel="Checklist zoeken"
          readerId={"checklistSearchScanReader"}
          onManualInput={() => overrideScanMethod("text")}
          onDeleteResult={reset}
          open={scanActive}
          data={scanResultUseScanner}
          onConfirm={() => {
            navigateToChecklist(scanResultUseScanner ?? "");
          }}
          onClose={() => {
            resetCamera();
            setScanActive(false);
          }}
        />
      )}

      <div className="px-2 pt-2">
        <div className="relative mb-4 w-full flex items-center mr-3 sm:mr-0">
          <Search className="absolute left-5 w-5 h-5 text-primary" />
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              (document.activeElement as HTMLInputElement).blur();
            }}
          >
            <Input
              // If scanner input type is wedge use false else true.
              autoFocus={false}
              value={serialNumberSearchValue}
              onChange={(e) => {
                setSerialNumberSearchValue(e.target.value);
              }}
              className="rounded-xl text-base border-0 border-zinc-700 pl-14 h-12 pr-4 py-0 bg-surface-lowest dark:bg-surface-highest placeholder:text-muted-foreground"
              placeholder={"Checklist zoeken..."}
            />
          </form>
          {/* TODO: hide only when wedge mode enabled... */}
          <div className="hidden 1flex p-2 h-full absolute right-0">
            <button
              className="border rounded-lg h-full px-2 bg-primary text-primary-foreground"
              onClick={handleScannerScan}
            >
              <ScanLine className="w-4 h-4 text-primary-foreground" />
            </button>
          </div>
        </div>

        {/* <div className="mb-4 flex items-center">
          <Input
            // autoFocus
            value={serialNumberSearchValue}
            onChange={(e) => {
              setSerialNumberSearchValue(e.target.value);
            }}
            className="border-color border-r-0 rounded-r-none rounded-l-lg h-12"
            placeholder="Checklists zoeken..."
          />
          <button
            className="border border-primary rounded-lg rounded-l-none px-4 h-12 bg-primary text-primary-foreground"
            onClick={handleScannerScan}
          >
            <ScanLine className="w-4 h-4 text-primary-foreground" />
          </button>
        </div> */}

        <Accordion
          // defaultValue="item-1"
          collapsible
          type="single"
          className="hidden mb-4 border-dashed 1hidden"
          disabled={
            templateChecklistsTemplates?.results
              ? templateChecklistsTemplates?.results?.length < 1
              : true
          }
        >
          <AccordionItem value="item-1" className="border-b-0">
            <AccordionTrigger
              className={`border p-4 rounded-lg 1border-t-0 w-full items-center hover:no-underline justify-between text-left ${
                templateChecklistsTemplates?.results &&
                templateChecklistsTemplates?.results?.length > 0
                  ? "bg-card border-transparent text-card-foreground"
                  : "bg-background border-color text-muted-foreground opacity-60"
              }`}
            >
              <div className="w-full">
                <span className={`text-sm font-semibold block`}>
                  Nieuwe checklist starten
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent className="border-x-2 border-b-2 border-card rounded-b-lg mt-[-8px] pb-3 1pt-2 border-t-8">
              {templateChecklistsTemplates?.results?.map((checklist, idx) => (
                <div
                  onClick={() => {
                    handleTemplateChecklistClick(checklist);
                  }}
                  key={`${checklist.id}-${idx}`}
                  className={`mt-3 1border rounded-lg px-3 py-1.5 h-fit flex items-start 1bg-card ${
                    serialNumberSearchValue === ""
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100 cursor-pointer"
                  }`}
                >
                  <div className="pt-0.5">
                    <PackagePlus className="w-4 h-4 mr-2 text-muted-foreground" />
                  </div>
                  <div>
                    <span className="block text-sm font-semibold text-card-foreground">
                      {checklist.title}
                    </span>
                    <span className="block text-xs font-normal text-muted-foreground/60">
                      Nieuwe inspectie starten
                    </span>
                  </div>

                  <div className="ml-auto pt-0.5">
                    <ArrowRight className="w-4 h-4 text-muted-foreground" />
                  </div>
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="px-2 flex items-center gap-x-2 pb-3 border-b">
        <Toggle
          size="sm"
          className="px-0 pr-4 pl-3 border"
          onPressedChange={setUserChecklistToggle}
          pressed={userChecklistsToggle}
        >
          <User className="mr-2 w-4 h-4" />
          Van mij
        </Toggle>
        <div className="h-5 border-r mx-3 w-1"></div>
        <Toggle
          size="sm"
          className="px-0 pr-4 pl-3 border"
          onPressedChange={(pressed) => {
            setNewChecklistToggle(pressed);
            setAcceptedChecklistToggle(false);
            setActiveChecklistToggle(false);
            setCompletedChecklistToggle(false);
          }}
          pressed={
            newChecklistsToggle &&
            !activeChecklistsToggle &&
            !acceptedChecklistsToggle &&
            !completedChecklistsToggle
          }
        >
          <Circle className="mr-2 w-4 h-4" />
          Nieuw
        </Toggle>
        <Toggle
          size="sm"
          className="px-0 pr-4 pl-3 border"
          onPressedChange={(pressed) => {
            setActiveChecklistToggle(pressed);
            setAcceptedChecklistToggle(false);
            setNewChecklistToggle(false);
            setCompletedChecklistToggle(false);
          }}
          pressed={
            activeChecklistsToggle &&
            !newChecklistsToggle &&
            !acceptedChecklistsToggle &&
            !completedChecklistsToggle
          }
        >
          <CircleDotDashed className="mr-2 w-4 h-4" />
          Actief
        </Toggle>
        <Toggle
          size="sm"
          className="px-0 pr-4 pl-3 border"
          onPressedChange={(pressed) => {
            setCompletedChecklistToggle(pressed);
            setActiveChecklistToggle(false);
            setAcceptedChecklistToggle(false);
            setNewChecklistToggle(false);
          }}
          pressed={
            completedChecklistsToggle &&
            !activeChecklistsToggle &&
            !newChecklistsToggle &&
            !acceptedChecklistsToggle
          }
        >
          <CircleDot className="mr-2 w-4 h-4" />
          Afgerond
        </Toggle>
        <Toggle
          size="sm"
          className="px-0 pr-4 pl-3 border"
          onPressedChange={(pressed) => {
            setAcceptedChecklistToggle(pressed);
            setActiveChecklistToggle(false);
            setNewChecklistToggle(false);
            setCompletedChecklistToggle(false);
          }}
          pressed={
            acceptedChecklistsToggle &&
            !newChecklistsToggle &&
            !activeChecklistsToggle &&
            !completedChecklistsToggle
          }
        >
          <CheckCircle2 className="mr-2 w-4 h-4" />
          Geaccepteerd
        </Toggle>
      </div>
      <div className="px-2">
        <div
          className="1border 1p-4 1rounded-lg overflow-scroll pt-4 md:h-[calc(100vh-98px)] h-[calc(100vh-152px)]"
          id="123"
          // style={{ height: "calc(100vh - 98px)" }}
        >
          {/* {debouncedSerialNumberSearchValue === "" && (
            <div className="w-full h-36 bg-card/50 1border rounded-lg flex items-center justify-center">
              <span className="text-sm text-muted-foreground/60">
                Begin te typen om te zoeken...
              </span>
            </div>
          )}

          {isLoadingChecklists && checklistsSearchEnabled && (
            <div className="w-full h-36 bg-card/50 border rounded-lg flex items-center justify-center">
              <Loader2 className="w-4 h-4 text-muted-foreground animate-spin" />
            </div>
          )}

          {debouncedSerialNumberSearchValue !== "" &&
            !isLoadingChecklists &&
            !checklists?.results && (
              <div className="w-full h-36 bg-card/50 border rounded-lg flex items-center justify-center">
                <span className="text-sm text-muted-foreground/60">
                  Geen checklists gevonden...
                </span>
              </div>
            )} */}

          {/* {debouncedSerialNumberSearchValue === "" &&
            recentChecklists &&
            recentChecklists?.results?.map((checklist) => (
              <div
                onClick={() => {
                  if (projectId === undefined) {
                    navigate(`/checklist/${checklist.id}`);

                    return;
                  }

                  // If project id is present it means we want to add the selected checklist to the project.
                  putChecklistToProject(
                    {
                      projectId: Number(projectId),
                      checklistId: checklist.id,
                    },
                    {
                      onSuccess: () => {
                        navigate(`/project/${projectId}`);
                      },
                      onError: (data) => {
                        alert(data.message);
                      },
                    },
                  );
                }}
                key={checklist.id}
                className={`h-fit group pl-2 pr-3 hover:bg-surface-high bg-surface-low mb-2 rounded-md cursor-pointer py-2 grid grid-cols-[auto_1fr_auto] items-center duration-150 ease-out `}
              >
                <div className="bg-surface-high group-hover:dark:bg-surface-highest dark:bg-surface-high mr-2.5 rounded-lg justify-center flex items-center text-sm text-muted-foreground font-medium h-12 w-12">
                  {checklist.state === 1 && (
                    <CheckCircle2 className="h-4 w-4 dark:text-emerald-400 text-green-500" />
                  )}
                  {checklist.state === 2 && (
                    <Circle className="h-4 w-4 text-muted-foreground" />
                  )}
                  {checklist.state === 3 && (
                    <span className="relative flex items-center justify-center text-yellow-500">
                      <div className="border-[3px] border-surface-high w-[14px] h-[14px] absolute bg-yellow-500 rounded-full" />
                      <Loader2 className="animate-[spin_3s_linear_infinite] z-10 w-5 h-5 text-yellow-500/50" />
                    </span>
                  )}
                </div>
                <div className="w-full overflow-hidden truncate">
                  <span className="leading-none block text-sm font-semibold 1text-card-foreground text-primary">
                    {checklist.serialNumber}{" "}
                  </span>
                  <span className="leading-none overflow-hidden truncate font-medium mt-1.5 flex items-center text-sm text-muted-foreground/60">
                    <span
                      className={`bg-surface-high ${
                        (checklist.percentage ?? 0) >= 100
                          ? "dark:text-emerald-400 text-green-500"
                          : ""
                      } dark:bg-surface-high rounded-md px-1.5 font-medium text-xs py-0.5`}
                    >
                      {checklist.percentage?.toFixed()}%
                    </span>
                    <div className="mx-1.5 h-1 w-1 rounded-full bg-white/20"></div>
                    <span className="truncate overflow-hidden w-fit">
                      {checklist.title}
                    </span>
                  </span>
                </div>
                <div className="ml-auto font-medium flex flex-col items-end gap-x-2">
                  <UserAvatar
                    small
                    narrow
                    left
                    id={checklist.createdBy?.value}
                  />
                  <span className="mt-1.5 whitespace-nowrap text-muted-foreground/60 bg-surface-high dark:bg-surface-high rounded-md px-1.5 font-medium text-xs py-0.5">
                    {checklist?.createdAt &&
                      timeAgo.format(
                        new Date(checklist?.createdAt ?? 0),
                        "mini-minute-now",
                      )}
                  </span>
                </div>
              </div>
            ))} */}

          {checklists?.results?.map((checklist) => (
            <div
              onClick={() => {
                if (projectId === undefined) {
                  navigate(`/checklist/${checklist.id}`);

                  return;
                }

                // If project id is present it means we want to add the selected checklist to the project.
                putChecklistToProject(
                  {
                    projectId: Number(projectId),
                    checklistId: checklist.id,
                  },
                  {
                    onSuccess: () => {
                      navigate(`/project/${projectId}`);
                    },
                    onError: (data) => {
                      alert(data.message);
                    },
                  },
                );
              }}
              key={checklist.id}
              className={`h-fit px-4 cursor-pointer py-2 grid grid-cols-[auto_1fr_auto] items-center duration-150 ease-out hover:dark:bg-black/5`}
            >
              <div className="bg-surface-high dark:bg-surface-high mr-2.5 rounded-lg justify-center flex items-center text-sm text-muted-foreground font-medium h-12 w-12">
                {checklist.state === 1 && (
                  <CheckCircle2 className="h-4 w-4 dark:text-emerald-400 text-green-500" />
                )}
                {checklist.state === 2 && (
                  <Circle className="h-4 w-4 text-muted-foreground" />
                )}
                {checklist.state === 3 && (
                  <span className="relative flex items-center justify-center text-yellow-500">
                    <div className="border-[3px] border-surface-high w-[14px] h-[14px] absolute bg-yellow-500 rounded-full" />
                    <Loader2 className="animate-[spin_3s_linear_infinite] z-10 w-5 h-5 text-yellow-500/50" />
                  </span>
                )}
              </div>
              <div className="w-full overflow-hidden truncate">
                <span className="leading-none block text-sm font-semibold 1text-card-foreground text-primary">
                  {checklist.serialNumber}{" "}
                </span>
                <span className="leading-none overflow-hidden truncate font-medium mt-1.5 flex items-center text-sm text-muted-foreground/60">
                  <span
                    className={`bg-surface-high ${
                      (checklist.percentage ?? 0) >= 100
                        ? "dark:text-emerald-400 text-green-500"
                        : ""
                    } dark:bg-surface-high rounded-md px-1.5 font-medium text-xs py-0.5`}
                  >
                    {checklist.percentage?.toFixed()}%
                  </span>
                  <div className="mx-1.5 h-1 w-1 rounded-full bg-white/20"></div>
                  <span className="truncate overflow-hidden w-fit">
                    {checklist.title}
                  </span>
                </span>
              </div>
              <div className="ml-auto font-medium flex flex-col items-end gap-x-2">
                <UserAvatar small narrow left id={checklist.createdBy?.value} />
                <span className="mt-1.5 whitespace-nowrap text-muted-foreground/60 bg-surface-high dark:bg-surface-high rounded-md px-1.5 font-medium text-xs py-0.5">
                  {checklist?.createdAt &&
                    timeAgo.format(
                      new Date(checklist?.createdAt ?? 0),
                      "mini-minute-now",
                    )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </LayoutV2>
  );
};
