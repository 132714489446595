"use client";

import { inpectionsHooks, templateChecklistsHooks } from "../../../../api";
import { schemas } from "../../../../api/schema";
// import DateFilters from "@/components/date-filter";
import { Button } from "../../../ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../ui/collapsible";
import { Input } from "../../../ui/input";
import { MultiSelect } from "../../../ui/multiselect";
import { Sheet } from "../../../ui/sheet";
import { Toggle } from "../../../ui/toggle";
// import UserAvatar from "@/components/user-avatar";
import { arrayHasValue } from "../../../../lib/utils";
// import { useOrganization } from "@clerk/nextjs";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import TimeAgo from "javascript-time-ago";
import {
  ArrowLeft,
  BadgeCheck,
  ChevronsUpDown,
  Circle,
  Contact,
  Loader,
  Loader2,
  PauseCircle,
  Search,
  XCircle,
} from "lucide-react";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { useInView } from "react-intersection-observer";
import nl from "relative-time-format/locale/nl";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import InspectionPopup from "./inspection-popup";
import Board from "./task-board/board";
import Card from "./task-board/card";
import Column, { ColumnBody, ColumnHeader } from "./task-board/column";

export enum Filter {
  active = 1,
  completed,
  rejected,
  accepted,
  generated,
}

enum InspectionsQueryOrdeBy {
  DefaultOrder = 0,
  CreatedAtAscending,
  CreatedAtDescending,
  UpdatedAtAscending,
  UpdatedAtDescending,
  AcceptedAtAscending,
  AcceptedAtDescending,
  TitleAscending,
  TitleDescending,
  SerialNumberAscending,
  SerialNumberDescending,
}

TimeAgo.addLocale(nl);
const timeAgo = new TimeAgo("nl-NL");

type Checklist = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Dtos_ChecklistSearchDto
>;

type Indicis = z.infer<
  typeof schemas.Checklab_WebApi_Features_Inspections_Get_StateIndices_V1_GetAllInspectionStateIndicesResponse
>;

export default function TaskBoard() {
  const [liveFeedEnabled, setLiveFeedEnabled] = useState(false);
  const [selectedChecklists, setSelectedChecklists] = useState<number[]>([]);
  const [checklistsSearchQuery, setChecklistSearchQuery] = useState<string>("");
  const [inspectionTitleSearchQuery, setInspectionTitleSearchQuery] =
    useState<string>("");
  const [debouncedSelectedChecklists] = useDebounce(selectedChecklists, 500);
  const [debouncedInspectionTitleSearchQuery] = useDebounce(
    inspectionTitleSearchQuery,
    500,
  );
  const [selectedCreatedBy, setSelectedCreatedBy] = useState<string[]>([]);
  const [selectedAcceptedBy, setSelectedAcceptedBy] = useState<string[]>([]);
  const [createdDateRange, setCreatedDateRange] = useState<
    DateRange | undefined
  >(undefined);
  const [acceptedDateRange, setAcceptedDateRange] = useState<
    DateRange | undefined
  >(undefined);

  const { data: indicis } = inpectionsHooks.useGet(
    "/inspections/indices",
    undefined,
    {
      refetchInterval: liveFeedEnabled ? 5000 : false,
    },
  );

  const { data: checklistTemplates, isLoading: allChecklistsIsLoading } =
    templateChecklistsHooks.useQuery("/templates/checklists", {
      queries: { page: 1, size: 24, title: checklistsSearchQuery },
    });

  const [selectedInspection, setSelectedInspection] = useState<
    Checklist | undefined | null
  >();

  function handleInspectionClick(inspection: Checklist | undefined | null) {
    setSelectedInspection(inspection);
  }

  // const { membershipList } = useOrganization({
  //   membershipList: { limit: 200, offset: 0 },
  // });

  return (
    <div className="1bg-background">
      <div
        // style={{ minHeight: "calc(100vh - 94px)" }}
        style={{ minHeight: "calc(100vh - 0px)" }}
        className="bg-backgroun1 1dark:bg-body-background 1dark:bg-background 1bg-surface-lowest bg-black"
      >
        <div className="border-b pt-8">
          <div className="pt-4 container max-w-[1480px] flex justify-between items-end">
            <div className="flex items-center mb-12">
              <div className="flex items-center">
                <div className="mr-4">
                  <ArrowLeft className="w-5 h-5" />
                </div>
                <h1 className="text-2xl font-medium text-primary">
                  Checklists
                </h1>
              </div>
            </div>
            <div className="py-2 flex items-center 1gap-x-2">
              <div className="hover:bg-muted h-8 border py-2 px-4 rounded-l-md border-r-0 1bg-secondary text-muted-foreground flex items-center">
                <Search className="w-4 h-4 mr-2" />
                <Input
                  onChange={(e) =>
                    setInspectionTitleSearchQuery(e.target.value)
                  }
                  className={`text-muted-foreground text-sm font-medium pl-2 pr-4 flex w-full ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 rounded-xl border-0 border-zinc-700 py-0 placeholder:text-muted-foreground`}
                  placeholder={`Inspecties zoeken...`}
                />
              </div>

              <div>
                <MultiSelect
                  showId
                  idRender={(id) => `CL-${id}: `}
                  placeholder="Checklists selecteren"
                  className="w-full"
                  buttonStyles="1bg-secondary hover:bg-muted rounded-l-none bg-transparent w-60 border h-8"
                  options={
                    checklistTemplates?.results?.map((checklist) => ({
                      value: `${checklist.id}`,
                      label: checklist.title ?? "",
                    })) ?? []
                  }
                  onFilterChange={(value) => {
                    console.log("FILTER", value);
                    setChecklistSearchQuery(value);
                  }}
                  selected={selectedChecklists.map((s) => `${s}`)}
                  onChangeValue={(values) =>
                    setSelectedChecklists(values.map((v) => Number(v)))
                  }
                />
              </div>
              <Toggle
                className="h-8 border ml-2"
                size={"sm"}
                pressed={liveFeedEnabled}
                onPressedChange={setLiveFeedEnabled}
              >
                <Contact className="w-4 h-4 mr-1.5" />
                Mijn checklists
              </Toggle>
              <Toggle
                className="h-8 border ml-2"
                size={"sm"}
                pressed={liveFeedEnabled}
                onPressedChange={setLiveFeedEnabled}
              >
                <div className="flex items-center justify-center gap-x-1.5 relative mr-1.5">
                  <div
                    className={`w-1 h-1 rounded-full bg-muted-foreground absolute ${
                      liveFeedEnabled ? "bg-red-500" : "bg-muted-foreground/40"
                    }`}
                  ></div>
                  {!liveFeedEnabled ? (
                    <Circle className="w-4 h-4" />
                  ) : (
                    <Loader2 className="w-4 h-4 animate-[spin_1.5s_linear_infinite]" />
                  )}
                </div>
                Realtime updates
              </Toggle>
            </div>
          </div>
        </div>

        <div className="container pb-6 max-w-[1480px]">
          <div className="mb-4">
            <div className="h-fit pt-2 flex flex-col items-start gap-y-2">
              <Collapsible className="w-full">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-x-2">
                    <span className="text-sm text-muted-foreground font-medium mr-1">
                      Aangemaakt:
                    </span>
                    {/* <DateFilters
                      defaultDateRange={"7"}
                      containerStyles="divide-x"
                      selectStyles="bg-secondary border-transparent font-medium"
                      dateRangeSelectButtonStyles="bg-secondary border-transparent font-medium"
                      small
                      col={false}
                      onChange={(dateRange) => setCreatedDateRange(dateRange)}
                    /> */}

                    <MultiSelect
                      showId={false}
                      className="w-full"
                      buttonStyles="bg-secondary border-transparent h-8 font-medium"
                      placeholder="Gebruikers"
                      options={
                        // membershipList?.map((membership) => ({
                        //   value: membership.publicUserData.userId ?? "",
                        //   label: `${membership.publicUserData.firstName} ${membership.publicUserData.lastName}`,
                        //   image: membership.publicUserData.imageUrl,
                        // })) ??

                        []
                      }
                      selected={selectedCreatedBy}
                      onChange={setSelectedCreatedBy}
                    />
                  </div>

                  <CollapsibleTrigger>
                    <Button
                      variant="ghost"
                      className="h-8 text-muted-foreground 1border"
                      size="sm"
                    >
                      <div className=" mr-2">
                        <ChevronsUpDown className="w-4 h-4 text-muted-foreground" />
                      </div>
                      Meer filters
                    </Button>
                  </CollapsibleTrigger>
                </div>
                <CollapsibleContent>
                  <div className="flex items-center w-fit gap-x-2 mt-3">
                    <span className="text-sm text-muted-foreground font-medium mr-1">
                      Geaccepteerd:
                    </span>
                    {/* <DateFilters
                      defaultDateRange="empty"
                      containerStyles="divide-x"
                      selectStyles="bg-secondary border-transparent font-medium"
                      dateRangeSelectButtonStyles="bg-secondary border-transparent font-medium"
                      small
                      col={false}
                      onChange={(dateRange) => setAcceptedDateRange(dateRange)}
                      dateRangeState={acceptedDateRange}
                    /> */}

                    <MultiSelect
                      showId={false}
                      className="w-full"
                      buttonStyles="bg-secondary border-transparent h-8 font-medium"
                      placeholder="Gebruikers"
                      options={
                        // membershipList?.map((membership) => ({
                        //   value: membership.publicUserData.userId ?? "",
                        //   label: `${membership.publicUserData.firstName} ${membership.publicUserData.lastName}`,
                        //   image: membership.publicUserData.imageUrl,
                        // })) ??
                        []
                      }
                      selected={selectedAcceptedBy}
                      onChange={setSelectedAcceptedBy}
                    />

                    <Button
                      disabled={
                        !acceptedDateRange && !arrayHasValue(selectedAcceptedBy)
                      }
                      onClick={() => {
                        setAcceptedDateRange(undefined);
                        setSelectedAcceptedBy([]);
                      }}
                      className="h-8 whitespace-nowrap bg-transparent"
                      variant={"outline"}
                    >
                      Reset filter
                    </Button>
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
          </div>

          <Sheet>
            {selectedInspection && selectedInspection.id && (
              <InspectionPopup
                id={selectedInspection.id}
                percentage={selectedInspection.percentage ?? 0}
              />
            )}

            <Board cols={5}>
              <ActiveColumn
                createdDateRange={createdDateRange}
                acceptedDateRange={acceptedDateRange}
                selectedCreatedBy={selectedCreatedBy}
                selectedAcceptedBy={selectedAcceptedBy}
                emptyLabel="Geen nieuwe inspecties"
                inspectionTitleSearchQuery={debouncedInspectionTitleSearchQuery}
                selectedChecklists={debouncedSelectedChecklists}
                amount={indicis?.activeAmount ?? 0}
                label={"Nieuw"}
                filter={Filter.generated}
                liveFeedEnabled={liveFeedEnabled}
                handleInspectionClick={handleInspectionClick}
                columnBackground={""}
                labelBackground={"dark:bg-neutral-400 bg-neutral-300"}
                cardBackground={""}
                cardHover={""}
                icon={() => (
                  <Circle className="text-muted-foreground w-4 h-4 mr-1.5" />
                )}
                labelIcon={
                  <Circle className="text-muted-foreground w-4 h-4 mr-1.5" />
                }
              />

              <ActiveColumn
                createdDateRange={createdDateRange}
                acceptedDateRange={acceptedDateRange}
                selectedCreatedBy={selectedCreatedBy}
                selectedAcceptedBy={selectedAcceptedBy}
                emptyLabel="Geen actieve inspecties"
                inspectionTitleSearchQuery={debouncedInspectionTitleSearchQuery}
                selectedChecklists={debouncedSelectedChecklists}
                amount={indicis?.activeAmount ?? 0}
                label={"Actief"}
                filter={Filter.active}
                liveFeedEnabled={liveFeedEnabled}
                handleInspectionClick={handleInspectionClick}
                columnBackground={""}
                labelBackground={"dark:bg-neutral-400 bg-neutral-300"}
                cardBackground={""}
                cardHover={""}
                icon={() => (
                  <div className="flex items-center justify-center gap-x-1.5 relative">
                    <div className="w-1 h-1 rounded-full bg-muted-foreground absolute"></div>
                    <Loader2 className="animate-[spin_2s_linear_infinite] w-4 h-4 text-muted-foreground" />
                  </div>
                )}
                labelIcon={
                  <div className="flex items-center justify-center gap-x-1.5 relative mr-1.5">
                    <div className="w-1 h-1 rounded-full bg-muted-foreground absolute"></div>
                    <Loader2 className="animate-[spin_2s_linear_infinite] w-4 h-4 text-muted-foreground" />
                  </div>
                }
              />

              <ActiveColumn
                createdDateRange={createdDateRange}
                acceptedDateRange={acceptedDateRange}
                selectedCreatedBy={selectedCreatedBy}
                selectedAcceptedBy={selectedAcceptedBy}
                emptyLabel="Geen verstoorde inspecties"
                inspectionTitleSearchQuery={debouncedInspectionTitleSearchQuery}
                selectedChecklists={debouncedSelectedChecklists}
                amount={indicis?.rejectedAmount ?? 0}
                label={"Verstoringen"}
                filter={Filter.rejected}
                liveFeedEnabled={liveFeedEnabled}
                handleInspectionClick={handleInspectionClick}
                columnBackground={"bg-orange-400/10 dark:bg-orange-900/25"}
                labelBackground={"bg-orange-400"}
                cardBackground={
                  "dark:bg-orange-900/20 hover:dark:bg-orange-900/40 bg-white"
                }
                cardHover={""}
                icon={() => <XCircle className="text-orange-400 w-4 h-4" />}
                labelIcon={
                  <XCircle className="text-orange-400 w-4 h-4 mr-1.5" />
                }
              />

              <ActiveColumn
                createdDateRange={createdDateRange}
                acceptedDateRange={acceptedDateRange}
                selectedCreatedBy={selectedCreatedBy}
                selectedAcceptedBy={selectedAcceptedBy}
                emptyLabel="Geen afgeronde inspecties"
                inspectionTitleSearchQuery={debouncedInspectionTitleSearchQuery}
                selectedChecklists={debouncedSelectedChecklists}
                amount={indicis?.completedAmount ?? 0}
                label={"Afgerond"}
                filter={Filter.completed}
                liveFeedEnabled={liveFeedEnabled}
                handleInspectionClick={handleInspectionClick}
                columnBackground={"bg-indigo-400/10 dark:bg-indigo-900/25"}
                labelBackground={"bg-indigo-400"}
                cardBackground={
                  "dark:bg-indigo-900/20 hover:dark:bg-indigo-900/40 bg-white"
                }
                cardHover={""}
                icon={() => <PauseCircle className="text-indigo-400 w-4 h-4" />}
                labelIcon={
                  <PauseCircle className="text-indigo-400 w-4 h-4 mr-1.5" />
                }
              />

              <ActiveColumn
                createdDateRange={createdDateRange}
                acceptedDateRange={acceptedDateRange}
                selectedCreatedBy={selectedCreatedBy}
                selectedAcceptedBy={selectedAcceptedBy}
                emptyLabel="Geen geaccepteerde inspecties"
                inspectionTitleSearchQuery={debouncedInspectionTitleSearchQuery}
                selectedChecklists={debouncedSelectedChecklists}
                amount={indicis?.acceptedAmount ?? 0}
                label={"Geaccepteerd"}
                filter={Filter.accepted}
                liveFeedEnabled={liveFeedEnabled}
                handleInspectionClick={handleInspectionClick}
                columnBackground={"bg-emerald-500/10 dark:bg-emerald-900/25"}
                labelBackground={"bg-emerald-500"}
                cardBackground={
                  "dark:bg-emerald-900/20 hover:dark:bg-emerald-900/40 bg-white"
                }
                cardHover={""}
                icon={(checklist) => (
                  <div className="flex items-center gap-x-1.5 font-medium">
                    {/* <UserAvatar
                      narrow
                      small
                      id={checklist?.acceptedBy?.value ?? ""}
                    /> */}
                    <BadgeCheck className="text-emerald-500 w-4 h-4" />
                  </div>
                )}
                labelIcon={
                  <BadgeCheck className="text-emerald-500 w-4 h-4 mr-1.5" />
                }
              />
            </Board>
          </Sheet>
        </div>
      </div>
    </div>
  );
}

function ActiveColumn({
  amount,
  liveFeedEnabled,
  handleInspectionClick,
  filter,
  label,
  columnBackground,
  labelBackground,
  cardBackground,
  cardHover,
  icon,
  labelIcon,
  selectedChecklists,
  inspectionTitleSearchQuery,
  emptyLabel,
  selectedCreatedBy,
  selectedAcceptedBy,
  createdDateRange,
  acceptedDateRange,
}: {
  amount: number;
  liveFeedEnabled: boolean;
  handleInspectionClick: (inspection: Checklist | undefined | null) => void;
  filter: Filter;
  label: string;
  columnBackground: string;
  labelBackground: string;
  cardBackground: string;
  cardHover: string;
  icon: (checklist: Checklist | null | undefined) => JSX.Element;
  labelIcon: JSX.Element;
  selectedChecklists: number[];
  selectedCreatedBy?: string[];
  selectedAcceptedBy?: string[];
  inspectionTitleSearchQuery: string;
  emptyLabel: string;
  createdDateRange?: DateRange;
  acceptedDateRange?: DateRange;
}) {
  const [page, setPage] = useState(1);

  const {
    data: activeChecklists,
    isLoading: activeChecklistsIsLoading,
    hasNextPage: activeChecklistsHasNextPage,
    fetchNextPage: activeChecklistsFetchNextPage,
  } = inpectionsHooks.useInfiniteQuery(
    "/inspections/search",
    {
      queries: {
        page: page,
        serialNumber: inspectionTitleSearchQuery,
        size: 12,
        orderBy: InspectionsQueryOrdeBy.UpdatedAtDescending,
        state: filter,
        fromTemplates: selectedChecklists,
        acceptedBy: selectedAcceptedBy,
        createdBy: selectedCreatedBy,
        "createdAt.from": createdDateRange
          ? format(createdDateRange?.from ?? new Date(), "yyyy-MM-dd 00:00:00")
          : format(startOfDay(addDays(new Date(), -7)), "yyyy-MM-dd HH:mm:ss"),
        "createdAt.to": createdDateRange
          ? format(
              addDays(createdDateRange?.to ?? new Date(), 1),
              "yyyy-MM-dd 00:00:00",
            )
          : format(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
        "acceptedAt.from": acceptedDateRange
          ? format(acceptedDateRange?.from ?? new Date(), "yyyy-MM-dd 00:00:00")
          : undefined,
        "acceptedAt.to": acceptedDateRange
          ? format(
              addDays(acceptedDateRange?.to ?? new Date(), 1),
              "yyyy-MM-dd 00:00:00",
            )
          : undefined,
      },
    },
    {
      refetchInterval: liveFeedEnabled ? 5000 : false,
      getPageParamList: () => ["page"],
      getNextPageParam: (lastPage, pages) =>
        (lastPage?.metadata?.page ?? 0) * (lastPage?.metadata?.size ?? 0) <
        (lastPage?.metadata?.totalCount ?? 0)
          ? {
              queries: {
                page: (lastPage?.metadata?.page ?? 0) + 1,
              },
            }
          : undefined,
    },
  );

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (
      inView &&
      arrayHasValue(activeChecklists?.pages) &&
      activeChecklistsHasNextPage
    ) {
      setTimeout(() => {
        activeChecklistsFetchNextPage();
      }, 500);
    }
  }, [activeChecklists, inView]);

  const activeChecklistsPages = activeChecklists?.pages
    ?.map((page) => page.results)
    .flat();

  const amountOfRows = activeChecklists?.pages[0].metadata?.totalCount ?? 0;

  useEffect(() => {
    setPage(1);
  }, [inspectionTitleSearchQuery]);

  const [secondRender, setSecondRender] = useState(false);

  const [newItems, setNewItems] = useState<string[]>([]);
  const [inspectionIds, setInspectionIds] = useState<string[]>([]);

  useEffect(() => {
    if (!secondRender && activeChecklists) {
      const ids =
        activeChecklists?.pages
          ?.map((page) => page.results)
          .flat()
          .map((inspection) => inspection?.id ?? "") ?? [];

      setInspectionIds(ids);
      setSecondRender(true);
    }

    if (secondRender && activeChecklists) {
      const newIds =
        activeChecklists?.pages
          ?.map((page) => page.results)
          .flat()
          .map((inspection) => inspection?.id ?? "") ?? [];

      const newIdsX = newIds.filter(
        (newInspectionId) => !inspectionIds.includes(newInspectionId),
      );

      setInspectionIds(
        activeChecklists?.pages
          ?.map((page) => page.results)
          .flat()
          .map((inspection) => inspection?.id ?? "") ?? [],
      );

      setNewItems(newIdsX);
    }
  }, [activeChecklists]);

  return (
    <Column background={columnBackground}>
      <ColumnHeader amount={amountOfRows} background={labelBackground}>
        {labelIcon}
        {label}
      </ColumnHeader>
      <ColumnBody>
        {activeChecklistsPages?.map((checklist) => (
          <Card
            newItem={newItems.includes(checklist?.id ?? "")}
            searchDescriptionQuery={inspectionTitleSearchQuery}
            onClick={() => handleInspectionClick(checklist)}
            color={labelBackground}
            background={cardBackground}
            key={checklist?.id}
            title={checklist?.title ?? ""}
            description={checklist?.serialNumber ?? ""}
            id={"CHE-123"}
            labels={[]}
            users={[]}
            percentage={checklist?.percentage ?? 0}
            created={timeAgo.format(new Date(checklist?.createdAt ?? 0))}
            createdUserId={checklist?.createdBy?.value ?? ""}
            icon={icon(checklist)}
          />
        ))}

        {!activeChecklistsIsLoading &&
          !arrayHasValue(activeChecklistsPages) && (
            <div
              ref={ref}
              className={`dark:text-muted-foreground text-muted-foreground pointer-events-none flex items-center justify-center h-8 text-xs font-medium w-full px-4 rounded-md leading-none`}
            >
              {emptyLabel}
            </div>
          )}
        {(activeChecklistsHasNextPage || activeChecklistsIsLoading) && (
          <div
            ref={ref}
            className={`dark:text-muted-foreground text-muted-foreground flex pointer-events-none items-center justify-center h-8 text-xs font-medium w-full px-4 rounded-md leading-none`}
          >
            <Loader className="w-4 h-4 mr-2 animate-spin" />
            Meer inspecties laden...
          </div>
        )}

        {!activeChecklistsHasNextPage &&
          !activeChecklistsIsLoading &&
          arrayHasValue(activeChecklistsPages) && (
            <div
              ref={ref}
              className={`dark:text-muted-foreground text-muted-foreground pointer-events-none flex items-center justify-center h-8 text-xs font-medium w-full px-4 rounded-md leading-none`}
            >
              Alle inspecties zijn opgehaald
            </div>
          )}
      </ColumnBody>
    </Column>
  );
}
