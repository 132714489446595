"use client";

import { PropsWithChildren } from "react";

export default function Board({
  children,
  cols,
}: PropsWithChildren<{ cols: number }>) {
  return <div className="grid grid-cols-5 gap-x-2.5">{children}</div>;
}
