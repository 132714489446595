"use client";

import { PropsWithChildren } from "react";

export default function Column({
  children,
  background,
}: PropsWithChildren<{ background?: string }>) {
  return (
    <div
      className={`border-2 dark:border-white/5 border-transparent rounded-lg h-fit ${
        background ? background : "dark:bg-accent/40 bg-accent/60"
      }`}
    >
      {children}
    </div>
  );
}

export function ColumnBody({ children }: PropsWithChildren) {
  return (
    <div className="rounded-lg grid gap-y-2 h-fit px-2 pb-2">{children}</div>
  );
}

export function ColumnHeader({
  children,
  background,
  amount,
}: PropsWithChildren<{
  background?: string;
  amount: number;
}>) {
  return (
    <div
      className={`grid grid-cols-2 items-center 1bg-accent/40 pl-2 pr-2 1h-12 pt-3 pb-3 gap-y-2`}
    >
      <span
        className={`whitespace-nowrap mr-2 flex items-center text-sm font-semibold pr-1.5 pl-1.5 py-0.5 rounded-md text-black dark:text-white w-fit`}
      >
        {children} ({amount})
      </span>
    </div>
  );
}
