"use client";

import { SheetTrigger } from "../../../../ui/sheet";
// import UserAvatar from "@/components/user-avatar";

type CardProps = {
  title: string;
  description: string;
  searchDescriptionQuery: string;
  id: string;
  labels: {
    color: string;
    value: string;
  }[];
  users: {
    userId: string;
  }[];
  icon?: JSX.Element;
  iconPrefix?: JSX.Element;
  created: string;
  createdUserId: string;
  percentage?: number;
  background?: string;
  color?: string;
  unfinishedChecks?: number;
  finishedChecks?: number;
  onClick: () => void;
  newItem: boolean;
};

export default function Card({ ...props }: CardProps) {
  const highlightedSpan = (text: string) => {
    if (!props.searchDescriptionQuery) return text;

    const regex = new RegExp(`(${props.searchDescriptionQuery})`, "gi");
    const rest = text.split(regex);

    return (
      <span>
        {rest.map((r) => {
          if (
            r.toLocaleLowerCase() ===
            props.searchDescriptionQuery.toLocaleLowerCase()
          ) {
            return (
              <span
                className="dark:bg-blue-700 text-primary bg-blue-300 rounded-sm"
                key={r}
              >
                {r}
              </span>
            );
          }

          return <span key={r}>{r}</span>;
        })}
      </span>
    );
  };

  return (
    <SheetTrigger asChild>
      <div
        onClick={props.onClick}
        className={`bg-white opacity-1 shadow-sm dark:bg-accent/60 rounded-md dark:border-transparent dark:border-0 border border-border overflow-hidden select-none ${
          props.newItem ? "animate-[fade-in_0.3s_ease-in-out_forwards]" : ""
        }`}
      >
        <div
          className={`pt-2 h-fit hover:bg-accent/80 cursor-pointer flex flex-col ease-out duration-150 ${
            props.background ? props.background : ""
          }`}
        >
          <div className="px-2">
            <div className="flex items-center">
              {props.iconPrefix}
              <span className="text-sm font-semibold block overflow-hidden truncate pr-1">
                {props.title}
              </span>
              <div className={`ml-auto relative`}>
                <div>{props.icon}</div>
              </div>
            </div>
            <span className="text-sm font-medium block text-muted-foreground max-w-full overflow-hidden truncate pr-5">
              {highlightedSpan(props.description)}
            </span>
            <span className="text-sm relative block text-muted-foreground/60 pt-2 pb-1">
              <div className="h-1 rounded-full bg-black/5 dark:bg-black/20 relative">
                {/* <span
                  className={`absolute right-0 top-[-22px] font-medium text-xs`}
                >
                  {props.percentage?.toFixed()}%
                </span> */}
                <div
                  className={`absolute h-1 ${props.color} rounded-full`}
                  style={{ width: `${props.percentage}%` }}
                >
                  {/* <div
                    className={`w-2.5 h-2.5 top-[-3px] absolute rounded-full bg-black right-0 ${props.color}`}
                  /> */}
                </div>
              </div>
            </span>
          </div>
          <div className="border-t dark:bg-white/5 dark:border-white/10 bg-black/5 border-black/10  pt-1.5 font-medium pb-1.5 pl-2 pr-2 flex items-center justify-between gap-x-1.5 1text-muted-foreground/60 mt-1.5">
            {/* <UserAvatar narrow small id={props.createdUserId} /> */}
            {/* <div className="w-[4px] h-[4px] rounded-full bg-muted-foreground/20" /> */}
            <span className="text-muted-foreground font-medium text-xs truncate overflow-hidden">
              {/* {props.created.replace("minuten", "min").replace("geleden", "")} */}
              {props.created}
            </span>
          </div>
          {/* <div
        className={`h-1 bg-blue-400/40 1rounded-r-full`}
        style={{ width: `${props.percentage}%` }}
      ></div> */}
        </div>
      </div>
    </SheetTrigger>
  );
}
