import React from "react";
import TaskBoard from "./board/task-board";

export const InspectionsRealtime: React.FC = () => {
  return (
    // <LayoutV2>
    //   <TaskBoard />
    // </LayoutV2>

    <TaskBoard />
  );
};
